<template>
	<v-dialog v-model="dialog" persistent width="350">
		<v-card>
			<v-card-title class="text-body-2 border-bottom">
				{{ title }}
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				{{ text }}
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn color="primary" depressed @click="closeModal">
					Ок
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "ModalWarning",
	props: {
		text: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			dialog: true,
		};
	},
	methods: {
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},
};
</script>

<style></style>
