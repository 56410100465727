import { kazToRus } from "@/helpers/helpers";

export default {
	methods: {
		// * Фильтр для поиска совпадений по адресам
		addressFilter(item, queryText, itemText) {
			itemText = kazToRus(itemText);
			queryText = kazToRus(queryText);

			return queryText.split(" ").every(e => itemText.includes(e));
		},
	},
};
